import { gql } from '@apollo/client';
import { WaitTimeSettings } from '@bondvet/types/waitTimeModel';

export interface WaitTime {
    _id: string;
    date: Date;
    firstName: string;
    lastName: string;
    pet: string;
    status: string;
}

export interface WaitTimesQueryResult {
    waitTimes: readonly WaitTime[];
    waitTimeSettings: WaitTimeSettings;
    frontOfHouseClinicScreens: readonly string[];
}

export const waitTimes = gql`
    query waitTimes {
        waitTimes {
            _id
            date
            firstName
            lastName
            pet
            status
        }
        waitTimeSettings {
            timezone
            showAppointmentStatus
        }
        frontOfHouseClinicScreens
    }
`;
